<template>
    <el-scrollbar class="myClass-container" :native="false">
        <div class="myclass-lists">
            <div class="class-panel" v-for="(classItem, classIndex) in classList" :key="classItem.c_id">
                <div class="class-panel-tit">
                    <h4>{{classItem.c_name}}</h4>
                </div>
                <div class="course-cont-box">
                    <div class="cengji-box">
                        <div class="class-panel-content">
                            <div class="data-unit">
                                <div class="unit-txt">
                                    <div class="unit-icon unit-member"></div>
                                    <div class="unit-title">成员数</div>
                                </div>
                                <div class="unit-num">
                                    {{classItem.s_num}}人
                                </div>
                            </div>
                            <div class="data-unit">
                                <div class="unit-txt">
                                    <div class="unit-icon unit-test"></div>
                                    <div class="unit-title">小测数</div>
                                </div>
                                <div class="unit-num">
                                    {{classItem.xc_num}}次
                                </div>
                            </div>
                            <div class="data-unit">
                                <div class="unit-txt">
                                    <div class="unit-icon unit-practice"></div>
                                    <div class="unit-title">实训数</div>
                                </div>
                                <div class="unit-num">
                                    {{classItem.sx_num}}次
                                </div>
                            </div>
                            <div class="data-unit">
                                <div class="unit-txt">
                                    <div class="unit-icon unit-homework"></div>
                                    <div class="unit-title">讨论数</div>
                                </div>
                                <div class="unit-num">
                                    {{classItem.tl_num}}次
                                </div>
                            </div>
                        </div>
                        <div class="class-panel-detail teacher-btns" v-if="role === 3">
                            <div class="detail-class-manage">
                                <a href="javascript:;" @click="toDetail(classItem)" class="detail-manage-btn">
                                    <i class="el-icon-menu"></i>班级管理
                                </a>
                            </div>
                            <div class="detail-btn-area">
                                <a href="javascript:;" class="detail-btn"><i class="el-icon-arrow-right"></i></a>
                                <div class="detail-start-btn" @click="startClass(classItem.c_id, classItem.c_name)">开始上课</div>
    <!--							<router-link class="detail-start-btn" :to="{name: 'qualityCourse'}">开始上课</router-link>-->
                            </div>
                        </div>
                    </div>
                    <div class="bottom-shadow1"></div>
                    <div class="bottom-shadow2"></div>
                    <div class="bottom-shadow3">
                        <div class="shaodow3-cont"></div>
                    </div>
                </div>
            </div>
            <div v-if="classList.length===0" style="padding: 20px; text-align: center; background: #fff; width: 1200px; font-size: 18px;">请先添加班级！</div>
<!--            <el-dialog title="选择课程" :visible.sync="chooseCourseDialog" width="645px" class="choose-course-dialog" :close-on-click-modal="false">-->
<!--                <div class="course-content">-->
<!--                    <div class="course-item" :class="{current: currentCourse === courseItem.course_id}"-->
<!--                         v-for="courseItem in courseList"-->
<!--                         :key="courseItem.course_id"-->
<!--                         @click="toggleCourse(courseItem.course_id)">-->
<!--                        <div class="course-cover">-->
<!--                            <img :src="courseItem.course_cover" alt="">-->
<!--                        </div>-->
<!--                        <div class="course-mask">-->
<!--                            <h3 class="course-title">{{courseItem.course_name}}</h3>-->
<!--                            <i class="iconfont">&#xe8e4;</i>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div slot="footer" class="dialog-footer">-->
<!--                    <el-button size="medium" @click="cancelBtn">取消</el-button>-->
<!--                    <el-button size="medium" type="primary" @click="confirmBtn">确定</el-button>-->
<!--                </div>-->
<!--            </el-dialog>-->

            <el-dialog :visible.sync="chooseCourseDialog" width="645px" class="choose-course-dialog" :close-on-click-modal="false">
                <div slot="title" class="dialog-title">
                    <span class="title">选择课程</span>
                    <span class="subtitle text-overflow" v-if="courseRecord">
                        您上次的上课记录: {{courseRecord.course_name}} > {{courseRecord.chapter_name}} > {{courseRecord.node_name}}
                    </span>
                </div>
                <div class="course-container">
                    <el-cascader-panel ref="courseCascaderPannel" class="courseCascader" v-model="courseCurrent" :options="courseListAll" :props="keyObj" clearable @change="changeCourse"></el-cascader-panel>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button size="medium" @click="cancelBtn">取消</el-button>
                    <el-button size="medium" type="primary" @click="confirmBtn">确定</el-button>
                </div>
            </el-dialog>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        name: "MyClassIndex",
        data() {
            return {
                classList: [],
				role: '',

                chooseCourseDialog: false,
                courseList: [],
                currentCourse: 0,
                currentClassName: '',
                currentClassId: 0,
                course_record_id: '',

                courseCurrent: '',
                courseListAll: [],
                keyObj: {
                    lazy: true,
                    lazyLoad: this.lazyLoad,
                    // checkStrictly: true,
                    label: 'label',
                    value: 'value',
                    children: 'children',
                },
                courseRecord: {

                }
            }
        },
		created() {
			this.watchRole();
			this.getCourseList();
			this.getClassList();
			this.getCourseListAll()
		},
        methods: {
            // 获取弹窗内 上课记录
            getCourseRecord() {
                let params = {
                    c_id: this.currentClassId
                }
                this.$shttp.axiosGetBy(this.$api.jd_getTeacherCourseOne, params, (res) => {
                    if (res.code === 200) {
                        this.courseRecord = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            // 获取弹窗内课程
            getCourseListAll() {
                this.$shttp.axiosGet(this.$api.admin_showTeaCourse, (res) => {
                    if (res.code === 200) {
                        // this.getCourseRecord()
                        let courseList = res.data.course_list
                        let courseTmp = []
                        courseList.forEach(item => {
                            let courseItemTmp = {
                                value: String(item.course_id),
                                label: item.course_name,
                                children: []
                            }
                            courseTmp.push(courseItemTmp)
                        })
                        this.courseListAll = courseTmp
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            lazyLoad(node, resolve) {
                this.$refs.courseCascaderPannel.clearCheckedNodes();
                const { level } = node;
                if (level === 3) {
                    resolve()
                }
                if (level === 1) {
                    this.$shttp.axiosGet(this.$api.admin_showTeaCourse, (res) => {
                        let alreadyList = JSON.parse(res.data.course_data)
                        let newAlreadyList = []
                        alreadyList.forEach(item => {
                            let includeIndex = newAlreadyList.findIndex(item1 => {
                                return String(item.course_id) === item1.course_id
                            })
                            if (includeIndex === -1) {
                                let alreadyItem = {
                                    course_id: String(item.course_id),
                                    children: [String(item.zhang_arr)]
                                }
                                newAlreadyList.push(alreadyItem)
                            } else {
                                newAlreadyList[includeIndex].children.push(String(item.zhang_arr))
                            }
                        })

                        let courseId = node.data.value
                        let nowLevel2 = newAlreadyList.find(item => item.course_id === courseId).children
                        this.$shttp.axiosGetBy(this.$api.courseContent, {id: courseId}, (res1) => {
                            if (res1.code === 200) {
                                let chapterList = res1.data.course_content
                                let chapterTmp = []
                                chapterList.forEach((item1, index1) => {
                                    if(nowLevel2.includes(String(index1))){
                                      let chapterItemTmp = {
                                        value: String(index1),
                                        label: item1.name,
                                        children: [],
                                        disabled: !nowLevel2.includes(String(index1))
                                      }
                                      chapterTmp.push(chapterItemTmp)
                                    }

                                })
                                resolve(chapterTmp);
                            } else {
                                this.$message.warning(res1.msg)
                            }
                        })
                    })
                } else if (level === 2) {
                    if (node.data.disabled) {
                        this.$message.warning('该章节未分配')
                    }
                    let courseId = node.parent.value
                    this.$shttp.axiosGetBy(this.$api.courseContent, {
                        id: courseId,
                        chapter: node.value
                    }, (res2) => {
                        if (res2.code === 200) {
                            let nodeList = res2.data.course_content.children
                            let nodeTmp = []
                            nodeList.forEach((item2, index2) => {
                                let nodeItemTmp = {
                                    value: String(index2),
                                    label: item2.name,
                                    leaf: 'leaf',
                                    disabled: node.data.disabled
                                }
                                nodeTmp.push(nodeItemTmp)
                            })
                            resolve(nodeTmp)
                        } else {
                            this.$message.warning(res2.msg)
                        }
                    })
                }
            },
            // 选择课程筛选章节
            changeCourse(val) {

            },
            getClassList() {
                let params = {
                  no_paging: 1,
                }
                this.$shttp.axiosGetBy(this.$api.statistics,params, (res) => {
                    if (res.code === 200) {
                        this.classList = res.data.data
                    }
                })
            },
            getCourseList() {
                let params = {
                    type: 1,
                    parent_id: 0,
                    sort: 1
                }
                this.$shttp.axiosGetBy(this.$api.courses, params, (res) => {
                    if (res.code === 200) {
                        this.courseList = res.list
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            toggleCourse(id) {
                this.currentCourse = id
            },
            startClass(id, name) {
                // this.getCourseListAll()
                // this.currentClassName = name;
                // this.currentClassId = id;
                // this.chooseCourseDialog = true
                if (this.courseListAll.length > 0) {
                    this.currentClassName = name;
                    this.currentClassId = id;
                    this.chooseCourseDialog = true
                    this.getCourseRecord()
                } else {
                    this.$message.warning('暂无可用课程')
                }
            },
            toDetail(item) {
                this.$router.push({path: '/teacherClass/detail', query: { classId: item.c_id, className: item.c_name }});
            },
			watchRole() {
				this.role = parseInt(localStorage.getItem('role'));
			},
            cancelBtn() {
                this.chooseCourseDialog = false;
                this.currentCourse = 0
            },
            // confirmBtn() {
            //     if (this.currentCourse === 0) {
            //         this.$message.warning('请先选择课程')
            //     } else {
            //         let params = {
            //             course_id: this.currentCourse,
            //             c_id: this.currentClassId,
            //         }
            //         this.$shttp.axiosPost(this.$api.startCourse, params, (res) => {
            //             if (res.code === 200) {
            //                 this.course_record_id = res.data.course_record_id
            //                 this.$store.dispatch('setRefreshVal', 0);
            //                 this.$router.push({
            //                     path: '/course/inClass',
            //                     query: {
            //                         classId: this.currentClassId,
            //                         className: this.currentClassName,
            //                         id: this.currentCourse,
            //                         recordId: this.course_record_id
            //                     }
            //                 })
            //                 /*let routeUrl = this.$router.resolve({
            //                     // path: '/course/inClass',
            //                     path: '/course/courseIndex',
            //                     query: {
            //                         classId: this.currentClassId,
            //                         className: this.currentClassName,
            //                         id: this.currentCourse,
            //                         recordId: this.course_record_id
            //                     }
            //                 });
            //
            //                 window.open('/' + routeUrl.href, '_blank')
            //                 this.chooseCourseDialog = false;
            //                 this.currentCourse = 0*/
            //             } else {
            //                 this.$message.warning(res.msg)
            //             }
            //         }, (err) => {
            //             console.log(err)
            //         })
            //     }
            // },
            confirmBtn() {
                if (this.courseCurrent.length === 0) {
                    return this.$message.warning('请先选择课程、章、节')
                }
                if (this.courseCurrent.length < 3) {
                    return this.$message.warning('请选到节')
                }
                let params = {
                    c_id: this.currentClassId,
                }
                if (this.courseCurrent.length > 0) {
                    params.course_id = this.courseCurrent[0]
                }
                if (this.courseCurrent.length > 1) {
                    params.chapter_sign = this.courseCurrent[1]
                }
                if (this.courseCurrent.length > 2) {
                    params.node = this.courseCurrent[2]
                }
                this.$shttp.axiosPost(this.$api.startCourse, params, (res) => {
                    if (res.code === 200) {
                        this.course_record_id = res.data.course_record_id
                        this.$store.dispatch('setRefreshVal', 0);
                        this.$message.success(res.msg)
                        let routeData = this.$router.resolve({
                          path:'/course/inClass',
                          query: {
                            classId: this.currentClassId,
                            className: this.currentClassName,
                            id: res.data.course_id,
                            recordId: this.course_record_id,
                            chapter: String(res.data.chapter_sign),
                            node: String(res.data.node)
                          }
                        })
                        window.open(routeData.href, '_blank')
                      // this.$router.push({
                      //       path: '/course/inClass',
                      //       query: {
                      //           classId: this.currentClassId,
                      //           className: this.currentClassName,
                      //           id: res.data.course_id,
                      //           recordId: this.course_record_id,
                      //           chapter: String(res.data.chapter_sign),
                      //           node: String(res.data.node)
                      //       }
                      //   })
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            }
        }
    }
</script>

<style lang="scss">
    .myClass-container {
        width: 100%;
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .myclass-lists {
        padding-top: 30px;
        width: 1200px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 auto;
        .class-panel {
            border-radius: 8px;
            width: 559px;
            overflow: hidden;
            margin-bottom: 54px;
            transition: margin 0.5s;
            &:nth-child(2n+1){
                margin-right: 80px;
            }
            &:hover {
                margin-top: -28px;
                .bottom-shadow2 {
                    margin-bottom: -15px;
                }
                .bottom-shadow3 {
                    margin-bottom: 0;
                }
				.course-cont-box {
					.cengji-box {
						.class-panel-detail {
							.detail-btn-area {
								.detail-start-btn {
									display: flex;
                                    cursor: pointer;
								}
								.detail-btn {
									display: none;
								}
							}
						}
					}
				}
            }
            .class-panel-tit {
                flex: 1;
                height: 96px;
                background-color: #005496;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                h4 {
                    font-size: 28px;
                    color: #FFFFFF;
                    font-weight: 500;
                    padding-left: 26px;
                }
            }
            &:nth-child(4n + 1) {
                .class-panel-tit {
                    background-color: #005496;
                }
            }
            &:nth-child(4n + 2) {
                .class-panel-tit {
                    background-color: #A8C95A;
                }
            }
            &:nth-child(4n + 3) {
                .class-panel-tit {
                    background-color: #F1B55B;
                }
            }
            &:nth-child(4n) {
                .class-panel-tit {
                    background-color: #F15B5C;
                }
            }
            .course-cont-box {
                position: relative;
                height: 187px;
                padding: 33px 31px 0;
                background: #fff;
                border-radius: 0 0 8px 8px;
                .cengji-box {
                    width: 100%;
                    height: auto;
                    position: relative;
                    z-index: 4;
                    .class-panel-content {
                        /*margin-top: 47px;*/
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        font-size: 16px;
                        padding: 24px 0 46px 0;
                        border-bottom: 1px solid #EAEAEA;
                        .data-unit {
                            /*margin-right: 50px;*/
                            .unit-txt {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                .unit-icon {
                                    width: 19px;
                                    height: 19px;
                                    background: url('../../assets/icons/sprite.png');
                                    margin-right: 9px;
                                    &.unit-member {
                                        background-position: -12px -5px;
                                    }
                                    &.unit-practice {
                                        background-position: -80px -5px;
                                    }
                                    &.unit-test {
                                        background-position: -46px -5px;
                                    }
                                    &.unit-homework {
                                        background-position: -114px -5px;
                                    }
                                }
                            }
                            .unit-num {
                                margin-top: 3px;
                                text-align: center;
                                font-size: 20px;
                                font-weight: bold;
                            }
                        }
                    }
                    .class-panel-detail {
						&.teacher-btns {
							justify-content: space-between;
							i.el-icon-menu {
								font-size: 16px;
								margin-right: 5px;
							}
						}
                        padding-right: 24px;
                        height: 62px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        .detail-btn-area {
                            .detail-btn {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 30px;
                                height: 30px;
                                border-radius: 15px;
                                background-color: #0B30B5;
                                color: #FFF;
                                box-shadow: 4px 2px 4px #2937E4;
                            }
							.detail-start-btn {
								display: none;
								justify-content: center;
								align-items: center;
								width: 100px;
								height: 30px;
								border-radius: 15px;
								background-color: #0B30B5;
								color: #FFF;
								box-shadow: 4px 2px 4px #2937E4;

							}
                        }
                    }
                }
                .bottom-shadow1 {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 3;
                    background: #fff;
                    box-shadow: 1px 7px 25px 0 rgba(90, 98, 123, 0.14);
                    border-radius: 0 0 8px 8px;
                }
                .bottom-shadow2 {
                    background: #ffffff;
                    box-shadow: 0 4px 22px 0 rgba(145, 151, 167, 0.13);
                    width: 530px;
                    height: 15px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    margin-left: -266px;
                    z-index: 2;
                    border-radius: 0 0 8px 8px;
                    transition: margin 0.5s;
                }
                .bottom-shadow3 {
                    width: 100%;
                    height: 28px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: margin 0.5s;
                    .shaodow3-cont {
                        width: 502px;
                        height: 28px;
                        background: #ffffff;
                        border-radius: 0 0 8px 8px;
                        position: absolute;
                        opacity: 0.93;
                        z-index: 1;
                        bottom: -28px;
                        left: 50%;
                        margin-left: -252.5px;
                        box-shadow: 0 4px 22px 0 rgba(145, 151, 167, 0.13);
                    }
                }
            }
        }
    }
    .choose-course-dialog {
        .dialog-title {
            display: flex;
            align-items: center;
            padding-right: 30px;
            .title {
                font-size: 18px;
                margin-right: 10px;
            }
            .subtitle {
                width: 1%;
                flex: 1;
            }
        }
        .el-dialog__body {
            border-top: 1px solid #EAEAEA;
            border-bottom: 1px solid #EAEAEA;
            padding: 0;
            .courseCascader {
                height: 300px;
                box-sizing: border-box;
                &.el-cascader-panel {
                    .el-scrollbar__wrap {
                        overflow-x: hidden !important;
                        height: 100%;

                        .el-scrollbar__view {
                            height: 100%;
                        }
                    }
                }
                ::v-deep .el-radio {
                    &.is-disabled {
                        & + .el-cascader-node__label {
                            color: #c0c4cc;
                        }
                        & + .el-cascader-node__label + .el-icon-arrow-right {
                            color: #c0c4cc;
                        }
                    }
                }
            }
            .course-content {
                display: flex;
                flex-wrap: wrap;
                margin: -20px -20px -20px -15px;
                max-height: 294px;
                overflow-y: auto;
                .course-item {
                    width: 140px;
                    height: 78px;
                    position: relative;
                    margin-left: 15px;
                    margin-top: 20px;
                    border-radius: 5px;
                    overflow: hidden;
                    cursor: pointer;
                    .course-cover {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #fdf6ef;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                        }
                    }
                    .course-mask {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, .5);
                        justify-content: center;
                        align-items: center;
                        display: none;
                        .course-title {
                            margin: 0;
                            color: #fff;
                            padding: 0 10px;
                            font-size: 15px;
                        }
                        .iconfont {
                            position: absolute;
                            top: 0;
                            right: 0;
                            padding: 3px;
                            font-size: 18px;
                            color: #409EFF;
                            display: none;
                        }
                    }
                    &:hover {
                        .course-mask {
                            display: flex;
                        }
                    }
                    &.current {
                        .course-mask {
                            display: flex;
                            .iconfont {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
